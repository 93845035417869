<template>
  <div> 
    <c-table
      ref="table"
      title="위험성평가 시나리오 비교 목록"
      :columns="grid.columns"
      :data="grid.data"
      :editable="false"
      :merge="grid.merge"
      @rowClick="rowClick"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol' && props.row['sopImprovementIds'] && props.row['ibmStepNames']">
          <q-chip
            v-for="(item, index) in $comm.ibmTagItems(props.row)"
            :key="index"
            :color="item.color"
            :clickable="true"
            text-color="white"
            style="margin-bottom:4px !important;"
            @click="linkClick(item, props.row)">
            {{item.title}}
            <c-assess-tooltip 
              :ibmTooltip="item.ibmTooltip"
              :ibmClassCd="item.ibmClassCd"
            />
          </q-chip>
        </template>
        <template v-else-if="col.name==='src'">
          <q-btn 
            round unelevated 
            size="6px"
            color="amber" 
            icon="search"
            @click="srcClick(props.row, props.pageIndex)" />
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import selectConfig from '@/js/selectConfig';
export default {
  name: 'risk-assess-compare-scenario',
  props: {
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
        merge: [
          { index: 0, colName: 'processName' },
        ],
      },
      riskAssessmentPlanId: '',
      assessVendorId: '',
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    window.addEventListener('beforeunload', this.evtClose);
    this.init();
  },
  beforeUnmount() {
    // window.removeEventListener('beforeunload', this.evtClose);
  },
  beforeDestroy() {
    alert(1)
    opener.g_winPopup = null
  },
  watch: {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.listUrl = selectConfig.saas.compare.list.url;
      this.editable = this.$route.meta.editable;

      // code setting
      if (opener) {
        opener.g_winPopup = this;
      }

      let query = window.location.search;
      if (!query) {
        let nativeUrl = window.location.href;
        nativeUrl = nativeUrl.replace('/#', '');

        let url = new URL(nativeUrl);
        const urlParams = url.searchParams;

        this.riskAssessmentPlanId = urlParams.get('riskAssessmentPlanId');
        this.assessVendorId = urlParams.get('assessVendorId');
      } else {
        let param = new URLSearchParams(query);
        if (param) {
          this.riskAssessmentPlanId = param.get('riskAssessmentPlanId');
          this.assessVendorId = param.get('assessVendorId');
        }
      }
      // list setting
      this.getList();
      this.setHeader();
    },
    getList() {
      // 선택된 위험성평가 정보가 있는 경우
      if (this.riskAssessmentPlanId && this.assessVendorId) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          riskAssessmentPlanId: this.riskAssessmentPlanId,
          assessVendorId: this.assessVendorId,
        };
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    rowClick(row) {
      if(!opener) {
        return;
      }
      // let sendStr = JSON.stringify( sendObj );

      // 부모 창에 Message를 보냄
      window.opener.postMessage(row, '*');
    },
    evtClose(e) {
      // e.preventDefault();
      window.opener.postMessage('CLOSE', '*');
      // e.returnValue = '';
      return 'bye';
    },
    setHeader() {
      this.grid.columns = [
        {
          name: 'processName',
          field: 'processName',
          label: '공종',
          align: 'center',
          style: 'width:120px',
          sortable: false,
        },
        {
          name: 'sopName',
          field: 'sopName',
          label: '작업',
          align: 'left',
          style: 'width:200px',
          sortable: false,
        },
        {
          name: 'src',
          field: 'src',
          label: '작업<br/>위치',
          align: 'center',
          style: 'width:40px',
          sortable: false,
          type: 'custom',
        },
        {
          required: true,
          name: 'riskHazardClassCd',
          field: 'riskHazardClassCd',
          label: '유해·위험요인<br/>(위험한 상황과 사건)',
          align: 'left',
          style: 'width:250px',
          sortable: false,
        },
        {
          name: 'relationLaw',
          field: 'relationLaw',
          label: '관련법규',
          align: 'left',
          style: 'width:130px',
          sortable: false,
        },
        {
          name: 'currentSafetyMeasures',
          field: 'currentSafetyMeasures',
          label: '현재안전조치',
          align: 'left',
          style: 'width:170px',
          sortable: false,
        },
        {
          name: 'afterRisk',
          field: 'afterRisk',
          label: '판단결과',
          align: 'center',
          style: 'width:90px',
          type: 'select',
          codeGroupCd: 'RAM_RISK_TYPE_CD',
          isChip: true,
          sortable: false,
        },
        {
          name: 'improvementMeasures',
          field: 'improvementMeasures',
          label: '추가 리스크관리 계획',
          align: 'left',
          style: 'width:170px',
          sortable: false,
        },
        {
          name: 'customCol',
          field: 'customCol',
          label: '개선',
          align: 'center',
          style: 'width:160px',
          type: 'custom',
          sortable: false
        },
        {
          name: 'assessDate',
          field: 'assessDate',
          label: '평가일',
          align: 'center',
          style: 'width:120px',
        },
        {
          name: 'assessUserName',
          field: 'assessUserName',
          label: '평가자',
          align: 'center',
          style: 'width:120px',
        },
      ]
    },
    srcClick(row) {
      this.row = row;
      this.popupOptions.title = '지도 위치'; // 지도 위치
      this.popupOptions.param = {
        riskAssessmentTargetId: row.riskAssessmentTargetId,
        maps: row.maps ? row.maps : [],
        deleteMaps: row.deleteMaps ? row.deleteMaps : [],
        sopMapId: row.sopMapId,
        disabled: true
      };
      this.popupOptions.target = () => import(`${'@/pages/saas/plan/riskAssessMap.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMapPopup;
    },
    closeMapPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;
      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      }
    },
    closeImprPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (flag !== 'delete') {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitStep[idIndex] = result.col1.ibmStepName;
            this.requestImprRow.ibmStepNames = splitStep.toString();
          }
        } else {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          let splitClass = this.$_.split(this.requestImprRow.ibmClassCds, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitId.splice(idIndex, 1)
            splitStep.splice(idIndex, 1)
            splitClass.splice(idIndex, 1)
            this.$set(this.requestImprRow, 'sopImprovementIds', splitId.toString())
            this.$set(this.requestImprRow, 'ibmStepNames', splitStep.toString())
            this.$set(this.requestImprRow, 'ibmClassCds', splitClass.toString())
          }
        }
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.getImprs();
      }
    },
  }
};
</script>
